<template>
  <!--  <el-scrollbar wrap-class="scrollbar-wrapper">-->
  <div>
    <el-menu
        :active-text-color="dynamicColor"
        :background-color="backgroundColor"
        :collapse="isCollapse"
        :default-active="currentLink"
        :text-color="textColor"
        class="el-menu-vertical-demo pl-0 menu"
        collapse-transition
        mode="vertical"
    >
      <sidebar-item
          :collapse="isCollapse"
          :trackers="trackers"
      />
    </el-menu>
  </div>
  <!--  </el-scrollbar>-->
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import SidebarItem from './SidebarItem.vue';

export default {
  name: 'SidebarIndex',
  components: {
    SidebarItem,
  },
  data() {
    return {
      dynamicColor: '#409EFF',
      textColor: '#fff',
    };
  },
  computed: {
    ...mapGetters([
      'sidebar',
    ]),
    ...mapState('trackers', ['trackers']),
    isCollapse() {
      return !this.sidebar.opened;
    },
    backgroundColor() {
      return this.$store.state.app.color;
    },
    currentLink() {
      let currentLink = this.$route.path;
      if (!currentLink.startsWith('/list-tracker') && !currentLink.startsWith('/module/')) {
        currentLink = '/' + currentLink.split('/')[1];
      }
      return currentLink;
    },
  },
  // mounted() {
  //   let a = this.$refs.menu.$children
  //   for (let i = 0; i < a.length; i++) {
  //     if (!a[i].to.startsWith('list-tracker')) {
  //       this.links.push(a[i].to.split('/')[1])
  //     } else {
  //       this.links.push(a[i].to)
  //     }
  //   }
  // }
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.el-menu-vertical-demo.el-menu--collapse {
  width: 36px;

  .el-tooltip {
    padding: 0 !important;
  }
}
</style>
<style
    lang="scss"
    scoped
>
.menu {
  overflow-y: scroll;
  overflow-x: hidden
}
</style>
