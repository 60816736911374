<template>
  <div
      :class="classObj"
      class="app-wrapper"
      style="min-width: 1000px;"
      v-on:scroll="handleScroll"
  >
    <el-header
        :style="backgroundColor"
        class="p-0"
    >
      <navbar />
    </el-header>
    <el-container>
      <div
          @click="handleClickOutside"
          class="drawer-bg"
          v-if="device==='mobile' && sidebar.opened"
      ></div>
      <sidebar
          :style="[{'position': scrolled ? 'fixed' : 'absolute'}, {'top': scrolled ? '0px' : '60px'}, {'height': scrolled ? '100%' : 'calc(100% - 60px)'} ]"
          class="sidebar-container"
      />
      <div class="main-container">
        <app-main />
        <issue-modal />
      </div>
    </el-container>
  </div>
</template>

<script charset="utf-8">
import {mapActions, mapGetters} from 'vuex';
import Navbar from './components/Navbar.vue';
import Sidebar from './components/Sidebar/index.vue';
import store from '../../store';
import notification from '../../notification/notify';
// import ResizeMixin from './mixin/ResizeHandler';
// import IssueModal from '../../components/dialog/modals/IssueModal.vue'
// import store from '../../store';

export default {
  name: 'layout',
  components: {
    Navbar,
    Sidebar,
    'app-main': () => import('./components/AppMain.vue'),
    // 'time-entry-dialog': timeEntryDialog,
    IssueModal: () => import('../../components/dialog/modals/IssueModal.vue'),
  },
  // mixins: [ResizeMixin],
  data() {
    return {
      scrolled: false,
      startPosition: 60,
    };
  },
  watch: {
    '$route'(to) {
      document.title = this.$t(to.meta.description);
    },
  },
  beforeMount: function () {
    // store.dispatch('permission/getUserPermission').then((data) => {
    //   store.commit('auth/SET_USER', data.user)
    //   store.commit('permission/SET_READY');
    // })
    //   .catch(function (e) {
    //     console.error(e);
    //     store.dispatch('auth/logout')
    //     next('/login');
    //   });
    this.$store.dispatch('getMyConfig');
    this.getSystemSetting();
    // this.getVersion();
    this.getMyWatchers();
    if (this.$route.query.issueId) {
      this.$store.commit('modal/OPEN_READ_MODAL', parseInt(this.$route.query.issueId));
    }
  },
  created() {
    this.getModules();
    this.fetchPermissions();
    this.fetchAvatar();
    this.fetchTrackers();
    window.addEventListener('focus', this.fetchPermissions);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.fetchPermissions);
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    backgroundColor() {
      return 'background: linear-gradient(to right, rgba(0, 0, 0, .5) 20%, ' + this.$store.state.app.color + ' 60%); background-color: ' + this.$store.state.app.color + ';';
    },
    ...mapGetters([
      'savedColumns',
    ]),
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
      };
    },
  },
  methods: {
    ...mapActions('modules', ['fetchModules']),
    ...mapActions('auth', ['fetchAvatar']),
    ...mapActions('trackers', ['fetchTrackers']),
    async getModules() {
      try {
        await this.fetchModules();
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t(e.response.data.error),
            'error');
      }
    },
    fetchPermissions() {
      store.dispatch('permission/getUserPermission').then((data) => {
        store.commit('permission/SET_READY');
        // this.$store.commit('auth/saveAuthInLocalStorage', data)
        store.commit('auth/SET_USER', data.user);
      });
    },
    handleScroll(event) {
      if (event.srcElement.scrollingElement.scrollTop > this.startPosition) {
        this.scrolled = true;
      }
      if (event.srcElement.scrollingElement.scrollTop < this.startPosition) {
        this.scrolled = false;
      }
    },
    handleClickOutside() {
      this.$store.dispatch('closeSideBar', {withoutAnimation: false});
    },
    async getVersion() {
      await this.$store.dispatch('getVersion');
    },
    async getSystemSetting() {
      this.$store.dispatch('getSystemSetting').then((response) => {
        if (!this.savedColumns || this.savedColumns.length === 0) {
          this.$store.commit('SET_SELECTED_COLUMNS', response.selectTheadIssue);
        } else {
          let index = this.savedColumns.findIndex((item) => item.active);
          if (index !== -1) {
            this.$store.commit('SET_SELECTED_COLUMNS', this.savedColumns[index].columns);
          } else {
            this.$store.commit('SET_SELECTED_COLUMNS', response.selectTheadIssue);
          }
        }
      });
    },
    async getMyWatchers() {
      await this.$store.dispatch('getMyWatchers');
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
@import '../../static/css/mixin';

/*.mobile{*/
/*.main-container{*/
/*padding-left: 0 !important;*/
/*}*/
/*}*/
</style>
