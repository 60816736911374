<!--TODO menu do całkowitej refaktoryzacji lub napisania od nowa-->
<template>
  <span class="sidebar">
    <router-link
        to="/dashboard"
        v-if="isVisible('dashboard')"
    >
      <el-menu-item
          :class="'submenu-title-noDropdown'"
          :index="'/dashboard'"
      >
        <div class="el-menu--iconCustom"><font-awesome-icon
            :icon-class="'tab'"
            class="svg-icon"
            icon="tachometer-alt"
        /></div>
        <span slot="title">{{ shortName($t('system.my_page')) }}</span>
      </el-menu-item>
    </router-link>
    <router-link
        to="/project"
        v-if="ifHasPermission(['project-list']) && isVisible('project')"
    >
      <el-menu-item
          :class="'submenu-title-noDropdown'"
          :index="'/project'"
      >
        <div class="el-menu--iconCustom"><font-awesome-icon
            :icon-class="'tab'"
            class="svg-icon"
            icon="briefcase"
        /></div>
        <span slot="title">{{ shortName($t('system.clients')) }}</span>
      </el-menu-item>
    </router-link>
    <template v-if="ifHasPermission(['issue-list']) && isVisible('list-tracker')">
      <router-link
          :key="item.id"
          :to="`/list-tracker/${item.id}`"
          v-for="item in trackers"
      >
        <el-menu-item
            :class="'submenu-title-noDropdown'"
            :index="`/list-tracker/${item.id}`"
        >
          <div class="el-menu--iconCustom"><font-awesome-icon
              :icon-class="'tab'"
              class="svg-icon"
              icon="tasks"
          /></div>
          <span slot="title">{{ shortName($t(item.translation)) }}</span>
        </el-menu-item>
      </router-link>
    </template>
    <template v-for="module in modules">
      <el-submenu
          :index="`/${module.name.toLowerCase()}/`"
          :key="module.name + module.id"
          v-if="ifHasPermission(modulePermissions[module.name.toLowerCase()])"
      >
        <template slot="title">
          <div class="submenu-element-container">
            <div class="el-menu--iconCustom"><font-awesome-icon
                :icon-class="'tab'"
                class="svg-icon"
                icon="globe"
            /></div>
            <span>{{ shortName($t(`module.${module.translation.toLowerCase()}`)) }}</span>
          </div>
        </template>
        <template v-for="submodule in module.submodules">
          <router-link
              :key="submodule.id"
              :to="{path: `/module/${module.name}/${submodule.name}`.toLowerCase()}"
          >
            <el-menu-item :index="`/module/${module.name}/${submodule.name}`.toLowerCase()">
              <div class="submenu-element-container submenu-element-container-child">
                <div class="el-menu--iconCustom"><font-awesome-icon
                    :icon-class="'tab'"
                    class="svg-icon"
                    icon="book"
                /></div>
                <span>{{ shortName($t(`module.${submodule.translation.toLowerCase()}`)) }}</span>
              </div>
            </el-menu-item>
          </router-link>
        </template>
      </el-submenu>
    </template>
    <!--    <el-menu-item-->
    <!--        :index="`/${item.name.toLowerCase()}`"-->
    <!--        :class="'submenu-title-noDropdown'"-->
    <!--    >-->
    <!--      <div class="el-menu&#45;&#45;iconCustom"><font-awesome-icon-->
    <!--          :icon-class="'tab'"-->
    <!--          class="svg-icon"-->
    <!--          icon="tasks"-->
    <!--      /></div>-->
    <!--      <span slot="title">{{ shortName($t(`module.${item.translation}`)) }}</span>-->
    <!--    </el-menu-item>-->
    <!--    <router-link :to="`/crm/opportunity`">-->
    <!--      <el-menu-item-->
    <!--          :index="`/crm`"-->
    <!--          :class="'submenu-title-noDropdown'"-->
    <!--      >-->
    <!--        <div class="el-menu&#45;&#45;iconCustom"><font-awesome-icon-->
    <!--            :icon-class="'tab'"-->
    <!--            class="svg-icon"-->
    <!--            icon="project-diagram"-->
    <!--        /></div>-->
    <!--        <span slot="title">{{ shortName($t('system.crm')) }}</span>-->
    <!--      </el-menu-item>-->
    <!--    </router-link>-->
    <router-link
        to="/calendar"
        v-if="ifHasPermission(['calendar-read']) && isVisible('calendar')"
    >
      <el-menu-item
          :class="'submenu-title-noDropdown'"
          :index="'/calendar'"
      >
        <div class="el-menu--iconCustom"><font-awesome-icon
            :icon-class="'tab'"
            class="svg-icon"
            icon="calendar-alt"
        /></div>
        <span slot="title">{{ shortName($t('system.calendar')) }}</span>
      </el-menu-item>
    </router-link>
    <!--    <router-link-->
    <!--        to="/knowledge_base"-->
    <!--        v-if="ifHasPermission(['knowledge-base-read'])"-->
    <!--    >-->
    <!--      <el-menu-item-->
    <!--          :class="'submenu-title-noDropdown'"-->
    <!--          :index="'/knowledge_base'"-->
    <!--      >-->
    <!--        <div class="el-menu&#45;&#45;iconCustom"><font-awesome-icon-->
    <!--            :icon-class="'tab'"-->
    <!--            class="svg-icon"-->
    <!--            icon="book"-->
    <!--        /></div>-->
    <!--        <span slot="title">{{ shortName($t('system.knowledge_base')) }}</span>-->
    <!--      </el-menu-item>-->
    <!--    </router-link>-->
    <router-link
        to="/settlements"
        v-if="ifHasPermission(['pm-generate-reports']) && isVisible('settlements')"
    >
      <el-menu-item
          class="submenu-title-noDropdown"
          index="/settlements"
      >
        <div class="el-menu--iconCustom"><font-awesome-icon
            :icon-class="'tab'"
            class="svg-icon"
            icon="file-alt"
        /></div>
        <span slot="title">{{ shortName($t('system.settlements')) }}</span>
      </el-menu-item>
    </router-link>
    <router-link
        to="/work-orders"
        v-if="ifHasPermission(['crm-workorder']) && isVisible('work-orders')"
    >
      <el-menu-item
          class="submenu-title-noDropdown"
          index="/work-orders"
      >
        <div class="el-menu--iconCustom">
          <font-awesome-icon
              :icon-class="'tab'"
              class="svg-icon"
              icon="cubes"
          />
        </div>
        <span slot="title">{{ shortName($t('system.work_orders')) }}</span>
      </el-menu-item>
    </router-link>
    <router-link
        to="/vacation-requests"
        v-if="ifHasPermission(['wo-leave-view-all', 'wo-leave-view-my']) && isVisible('vacation-requests')"
    >
      <el-menu-item
          class="submenu-title-noDropdown"
          index="/vacation-requests"
      >
        <div class="el-menu--iconCustom">
          <font-awesome-icon
              :icon-class="'tab'"
              class="svg-icon"
              icon="couch"
          />
        </div>
        <span slot="title">{{ shortName($t('system.vacation_requests')) }}</span>
      </el-menu-item>
    </router-link>
    <!--    <router-link-->
    <!--        to="/gantt_diagram"-->
    <!--        v-if="ifHasPermission(['show-gantt'])"-->
    <!--    >-->
    <!--      <el-menu-item-->
    <!--          :index="'/gantt_diagram'"-->
    <!--          :class="'submenu-title-noDropdown'"-->
    <!--      >-->
    <!--        <div class="el-menu&#45;&#45;iconCustom"><font-awesome-icon-->
    <!--            :icon-class="'tab'"-->
    <!--            class="svg-icon"-->
    <!--            icon="chart-bar"-->
    <!--        /></div>-->
    <!--        <span slot="title">{{ shortName($t('system.resource')) }}</span>-->
    <!--      </el-menu-item>-->
    <!--    </router-link>-->
    <router-link
        to="/payroll"
        v-if="ifHasPermission(['payroll-all', 'payroll-own']) && isVisible('payroll')"
    >
      <el-menu-item
          :class="'submenu-title-noDropdown'"
          :index="'/payroll'"
      >
        <div class="el-menu--iconCustom"><font-awesome-icon
            :icon-class="'tab'"
            class="svg-icon"
            icon="calendar-alt"
        /></div>
        <span slot="title">{{ shortName($t('system.payroll')) }}</span>
      </el-menu-item>
    </router-link>
    <router-link
        to="/visits"
        v-if="ifHasPermission(['visits-read']) && isVisible('visits')"
    >
      <el-menu-item
          :class="'submenu-title-noDropdown'"
          :index="'/visits'"
      >
        <div class="el-menu--iconCustom"><font-awesome-icon
            :icon-class="'tab'"
            class="svg-icon"
            icon="handshake"
        /></div>
        <span slot="title">{{ $t('system.visits') }}</span>
      </el-menu-item>
    </router-link>
    <router-link
        to="/orders"
        v-if="ifHasPermission(['orders-read']) && isVisible('orders')"
    >
      <el-menu-item
          :class="'submenu-title-noDropdown'"
          :index="'/orders'"
      >
        <div class="el-menu--iconCustom"><font-awesome-icon
            :icon-class="'tab'"
            class="svg-icon"
            icon="shopping-cart"
        /></div>
        <span slot="title">{{ $t('system.orders') }}</span>
      </el-menu-item>
    </router-link>
    <router-link
        to="/gifts"
        v-if="ifHasPermission(['gifts-read']) && isVisible('gifts')"
    >
      <el-menu-item
          :class="'submenu-title-noDropdown'"
          :index="'/gifts'"
      >
        <div class="el-menu--iconCustom"><font-awesome-icon
            :icon-class="'tab'"
            class="svg-icon"
            icon="gift"
        /></div>
        <span slot="title">{{ $t('system.gifts') }}</span>
      </el-menu-item>
    </router-link>
    <template>
      <el-submenu
          :index="`/targets/`"
          v-if="ifHasPermission(['targets-read']) && isVisible('targets')"
      >
        <template slot="title">
          <div class="submenu-element-container">
            <div class="el-menu--iconCustom"><font-awesome-icon
                :icon-class="'tab'"
                class="svg-icon"
                icon="dollar-sign"
            /></div>
            <span>{{ shortName($t(`system.targets`)) }}</span>
          </div>
        </template>
        <template>
          <router-link
              to="/targets/contractors"
          >
            <el-menu-item :index="`/targets/contractors`">
              <div class="submenu-element-container submenu-element-container-child">
                <div class="el-menu--iconCustom"><font-awesome-icon
                    :icon-class="'tab'"
                    class="svg-icon"
                    icon="user"
                /></div>
                <span>{{ shortName($t(`system.contractors`)) }}</span>
              </div>
            </el-menu-item>
          </router-link>
        </template>
        <template>
          <router-link
              to="/targets/contractor-groups"
          >
            <el-menu-item :index="`/targets/contractor-groups`">
              <div class="submenu-element-container submenu-element-container-child">
                <div class="el-menu--iconCustom"><font-awesome-icon
                    :icon-class="'tab'"
                    class="svg-icon"
                    icon="users"
                /></div>
                <span>{{ shortName($t(`system.contractor_groups`)) }}</span>
              </div>
            </el-menu-item>
          </router-link>
        </template>
      </el-submenu>
    </template>
    <router-link
        to="/active_clients"
        v-if="ifHasPermission(['active-clients-read']) && isVisible('active_clients')"
    >
      <el-menu-item
          :class="'submenu-title-noDropdown'"
          :index="'/active_clients'"
      >
        <div class="el-menu--iconCustom"><font-awesome-icon
            :icon-class="'tab'"
            class="svg-icon"
            icon="chart-line"
        /></div>
        <span slot="title">{{ $t('system.activeclients') }}</span>
      </el-menu-item>
    </router-link>
    <!--    <router-link-->
    <!--        to="/invoice"-->
    <!--        v-if="ifHasPermission(['show-all-invoice', 'show-own-invoice'])"-->
    <!--    >-->
    <!--      <el-menu-item-->
    <!--          :class="'submenu-title-noDropdown'"-->
    <!--          :index="'/invoice'"-->
    <!--      >-->
    <!--        <div class="el-menu&#45;&#45;iconCustom"><font-awesome-icon-->
    <!--            :icon-class="'tab'"-->
    <!--            class="svg-icon"-->
    <!--            icon="file-invoice-dollar"-->
    <!--        /></div>-->
    <!--        <span slot="title">{{ shortName($t('system.invoices')) }}</span>-->
    <!--      </el-menu-item>-->
    <!--    </router-link>-->
    <router-link
        to="/administration/user"
        v-if="ifHasPermission(['admin']) && isVisible('administration')"
    >
      <el-menu-item
          :class="'submenu-title-noDropdown'"
          :index="'/administration'"
      >
        <div class="el-menu--iconCustom"><font-awesome-icon
            :icon-class="'tab'"
            class="svg-icon"
            icon="users-cog"
        /></div>
        <span slot="title">{{ shortName($t('system.administration')) }}</span>
      </el-menu-item>
    </router-link>
  </span>
</template>

<script>
import {mapState} from 'vuex';
import menuItems from '@/config/menuItems';

export default {
  name: 'SidebarItem',
  props: {
    collapse: Boolean,
    trackers: Array,
  },
  computed: {
    ...mapState('modules', ['modules']),
    modulePermissions() {
      const modulesPermissions = {};
      this.modules.forEach(module => {
        const moduleName = module.name;
        const submodulesNames = module.submodules.map(submodule => submodule.name);
        modulesPermissions[moduleName.toLowerCase()] = submodulesNames.map(submoduleName => `${moduleName.toLowerCase()}-${submoduleName.toLowerCase()}-read`);
      });
      return modulesPermissions;
    },
  },
  methods: {
    shortName: function (value) {
      if (this.collapse) {
        return value;
      } else {
        if (value.length > 25) {
          return value.slice(0, 25) + '...';
        } else {
          return value;
        }
      }
    },
    isVisible(element) {
      return menuItems(element, process.env.VUE_APP_MENU || 'default');
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>

.el-submenu {
  li {
    padding: 0 25px 0 10px !important;
  }
}

</style>

<style
    lang="scss"
    rel="stylesheet/scss"
>

@import '../../../../static/css/sidebarItem';

</style>
