export default function menuItems(element, listName) {
  const viewsList = {};

  viewsList.default = [
    'dashboard',
    'project',
    'list-tracker',
    'calendar',
    'settlements',
    'work-orders',
    'vacation-requests',
    'payroll',
    'visits',
    'orders',
    'gifts',
    'targets',
    'active_clients',
    'administration',
  ];

  viewsList.SK = [
    'dashboard',
    'project',
    'list-tracker',
    'calendar',
    'settlements',
    'vacation-requests',
    'visits',
    'orders',
    'gifts',
    'targets',
    'administration',
  ];

  viewsList.EFP = [
    'dashboard',
    'project',
    'list-tracker',
    'calendar',
    'work-orders',
    'vacation-requests',
    'administration',
  ];

  viewsList.ABILEX = [
    'dashboard',
    'project',
    'list-tracker',
    'settlements',
    'payroll',
    'active_clients',
    'administration',
  ];

  return !!viewsList[listName]
    .find((view) => view === element);
}
