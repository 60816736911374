<template>
  <div class="navbar">
    <div class="main-header container-fluid pr-0 pl-0">
      <div class="d-flex justify-content-between w-100">
        <div class="main-title d-flex align-items-center">
          <span slot="title">
            {{ _self['systemSetting'].instance_name ? _self['systemSetting'].instance_name : 'AbiFlow 🚀' }}
          </span>
          <Hamburger
              :isActive="sidebar.opened"
              :toggleClick="toggleSideBar"
              class="hamburger-container"
          />
          <div
              class="search-form input-main-search"
              v-if="ifHasPermission(['project-list', 'issue-list', 'payroll-all', 'payroll-own'])"
          >
            <el-input
                :maxlength="200"
                :placeholder="$t('system.search')"
                @keyup.enter.native="searchParseClicked"
                v-model="searchParse"
            >
              <el-button
                  @click="searchParseClicked"
                  icon="el-icon-search"
                  slot="append"
              />
            </el-input>
          </div>
          <div
              v-if="user && user.account_name && user.account_id"
              class="ml-3"
          >
            <span class="welcome-message">
              <strong>{{ $t('system.welcome') }}</strong>
              {{ user.account_name }}
            </span>
          </div>
        </div>
        <div class="nav">
          <div class="right-menu">
            <CreateTrackerButton
                class="nav-interactive-element"
                v-if="ifHasPermission(['pm-add-issues'])"
            />
            <Stopwatch class="nav-interactive-element" />
            <FavoriteProjects
                class="nav-interactive-element"
                v-if="ifHasPermission(['project-list'])"
            />
            <ThemePicker class="right-menu-item nav-interactive-element theme-switch" />
            <LangSelect
                @changeLanguage="changeLanguage"
                class="nav-interactive-element"
            />
            <el-dropdown
                class="right-menu-item"
                placement="bottom-end"
                trigger="click"
            >
              <div
                  class="d-flex align-items-center nav-interactive-element pointer"
                  style="outline: none"
              >
                <div
                    :style="`background-image: url('${avatar}');`"
                    class="avatar"
                    v-if="avatar"
                />
                <span
                    class="avatar-alt"
                    v-else
                >
                    {{ abbreviation(`${user.first_name} ${user.last_name}`) }}
                  </span>
                <!--                <span v-else>-->
                <!--                  <svg-icon icon-class="profile-o" class="icon-navbar"/>-->
                <!--                </span>-->
              </div>
              <el-dropdown-menu
                  :append-to-body="false"
                  slot="dropdown"
                  style="padding: 5px 0; margin-top: 5px;"
              >
                <router-link to="/profile">
                  <el-dropdown-item>
                    {{ $t('system.profile') }}
                  </el-dropdown-item>
                </router-link>
                <el-dropdown-item @click.native="logout">
                  {{ $t('system.logout') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import notification from '../../../notification/notify';
import {abbreviation} from '../../../helpers/functions';

export default {
  components: {
    CreateTrackerButton: () => import('../../../components/CreateTrackerButton/CreateTrackerButton.vue'),
    Stopwatch: () => import('../../../components/Stopwatch/index.vue'),
    FavoriteProjects: () => import(/* webpackChunkName: "navbar" */ '../../../components/FavoriteProjects/index.vue'),
    Hamburger: () => import(/* webpackChunkName: "navbar" */ '../../../components/Hamburger/index.vue'),
    LangSelect: () => import(/* webpackChunkName: "navbar" */ '../../../components/LangSelect/index.vue'),
    ThemePicker: () => import(/* webpackChunkName: "navbar" */ '../../../components/ThemePicker/index.vue'),
  },
  computed: {
    ...mapState('auth', ['avatar']),
    ...mapGetters('auth', [
      'user',
      'username',
    ]),
    ...mapGetters([
      'sidebar',
      'version',
      'systemSetting',
    ]),
  },
  data: function () {
    return {
      searchParse: '',
    };
  },
  methods: {
    abbreviation(str) {
      return abbreviation(str);
    },
    toggleSideBar() {
      this.$store.dispatch('toggleSideBar');
    },
    async logout() {
      try {
        await this.$store.dispatch('auth/logout');
        // this.$store.dispatch('time/delAllTimes');
        // this.changeLanguageMixin(window.localStorage.getItem('langGlobal') || 'en')
        await this.$router.push('/login');
      } catch (error) {
        console.log('error', error);
        notification.notify(
            this.$t('notify.error'),
            this.$t(error.response.data.error),
            'error',
        );
      }
    },
    changeLanguage(lang) {
      window.localStorage.setItem('langLocal', lang);
      this.changeLanguageMixin(lang);
    },
    searchParseClicked() {
      if (this.searchParse !== '') {
        this.$store.commit('SET_SEARCHING', this.searchParse);
        this.$store.commit('SET_SEARCH_CLICKED', 1);
        this.$router.push('/search');
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.hide-wrap {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  display: block;
}
</style>
<style
    lang="scss"
    rel="stylesheet/scss"
>

.input-main-search {
  margin-left: 10px;

  input, .el-input-group__append {
    background-color: rgba(0, 0, 0, .25) !important;
    color: white !important;
    border: none !important;
    height: 36px !important;
    transition-duration: .2s;
  }

  input {
    width: 170px;
    border-radius: 30px 0 0 30px;

    &:hover, &:focus {
      background-color: black !important;
    }
  }

  .el-input-group__append {
    border-radius: 0 30px 30px 0;

    &:hover {
      background-color: black !important;
    }
  }
}

.navbar {
  margin: 0;
  padding: 0;
  border: none !important;
  width: 100%;

  .main-title {
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    .welcome-message {
      color: white;
      font-weight: normal;
      font-size: .9rem;
    }

    .search-form {
      .el-button, input {
        border: none;
        outline: none;
      }
    }

    span {
      color: #ffffff;
      min-width: 200px;
      text-align: center;
      font-weight: bold;
      font-size: 19px;
    }
  }

  .hamburger-container {
    float: left;
    padding: 0 10px;
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .nav {
    height: 60px;

    .right-menu {
      height: 100%;
      display: flex;
      align-items: stretch;

      .nav-interactive-element {
        transition-duration: .2s;
        padding: 8px;
        height: 100%;

        &:hover {
          background-color: rgba(0, 0, 0, .33)
        }
      }

      .right-menu-item {
        /*display: inline-block;*/
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        transition: .5s;

        &:hover {
          /*box-shadow: inset 0px 0px 80px #000;*/
        }

        .avatar {
          cursor: pointer;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          background-size: cover;
          background-position: top center;
        }

        .avatar-alt {
          font-size: 1.1em;
          color: #ffffff;
          padding: 8px;
          border: 1px solid #ffffff;
          border-radius: 50%;
        }

        .icon-navbar {
          position: relative;
          font-size: 28px;
          color: #fff;
        }

        .fullscreen_small {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
